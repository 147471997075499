import { axios } from '@axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

export const getInsurances = ({ search = '' }): Promise<any> => {
    return axios.get(`insurance?search=${search}`);
};

type QueryFnType = typeof getInsurances;

type UseInsurancesOptions = {
    params?: {
        search?: string;
    };
    config?: QueryConfig<QueryFnType>;
};

export const useInsurances = ({ params, config }: UseInsurancesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['insurances'],
        queryFn: () => getInsurances(params),
        select: (res: any) => res.data,
        ...config,
    });
};

export const getDoctorInsurances = (doctorId: number): Promise<any> => {
    return axios.get(`insurance/doctor-insurances/${doctorId}`);
};

type DoctorInsurancesQueryFnType = typeof getDoctorInsurances;

type UseDoctorInsurancesOptions = {
    doctorId?: number;
    config?: QueryConfig<DoctorInsurancesQueryFnType>;
};

export const useDoctorInsurances = ({
    doctorId,
    config,
}: UseDoctorInsurancesOptions) => {
    return useQuery<ExtractFnReturnType<DoctorInsurancesQueryFnType>>({
        queryKey: ['doctor-insurances', doctorId],
        queryFn: () => (doctorId ? getDoctorInsurances(doctorId) : null),
        select: (res: any) => res?.data || [],
        ...config,
    });
};
