import { UserType } from '@typing/global';
import { decryptData } from '@utils/EncryptDecrypt/EncryptDecrypt';
import dayjs from 'dayjs';
import * as yup from 'yup';

export const TableHeadCells = (IsOD: boolean, usertype: UserType) => {
    const list = [
        {
            id: 'fullName',
            label: 'Patient name',
            sort: true,
            isEncrypted: true,
            type: IsOD ? 'text' : 'linkToAppointment',
            nested: 'fullName',
            style: { textWrap: 'nowrap', textTransform: 'capitalize' },
        },
        {
            id: 'dob',
            label: 'DOB',
            sort: true,
            isEncrypted: true,
            type: 'date',
            nested: 'dob',
            style: { textWrap: 'nowrap' },
        },
        {
            id: 'createdAt',
            sort: true,
            label: 'Referred on',
            type: 'date',
            nested: 'createdAt',
        },
        {
            id: 'referredDoctor',
            label: IsOD ? 'Referred to' : 'Referred by',
            nested: IsOD ? 'referedTo.name' : 'referedBy.name',
            sort: true,
            style: { textTransform: 'capitalize', textWrap: 'nowrap' },
        },
        {
            id: 'consultationDate',
            label: 'Consultation date',
            //sort: true,
            type: IsOD ? 'date' : 'appointmentdate',
            nested: 'consultationAppointment.date',
        },
        {
            id: 'surgeryDate',
            label: 'Surgery date',
            //sort: true,
            type: IsOD ? 'date' : 'appointmentdate',
            nested: 'surgeryAppointment.date',
        },
        {
            id: 'bookingStatus',
            label: 'Status',
            sort: true,
            nested: 'bookingStatus',
            type: 'status',
            style: { borderRadius: '4px' },
        },
        {
            id: 'returnToReferrer',
            label: 'Return to referrer',
            type: 'boolean',
            nested: 'consultReason.patientReturn',
        },
        {
            id: 'hasConsultNote',
            label: 'Consult note',
            type: 'hasConsultNote',
            nested: 'hasConsultNote',
        },
        {
            id: 'referralType',
            label: 'Referral Type',
            nested: 'referralType',
            style: { textTransform: 'capitalize' },
        },
        {
            id: 'actions',
            label: 'Actions',
            style: { textAlign: 'center' },
        },
    ];
    if (usertype === 'doctor') {
        list.splice(-1, 0, {
            id: 'directMessage',
            label: 'Direct messege',
            type: 'linkToChat',
            nested: 'directMessage',
        });
    }

    return list;
};

export const TableDataFields = (IsOD: boolean, usertype: UserType) => {
    const list = [
        'fullName',
        'dob',
        'createdAt',
        'referredDoctor',
        'consultationDate',
        'surgeryDate',
        'bookingStatus',
        'returnToReferrer',
        'hasConsultNote',
        'referralType',
        'actions',
    ];
    if (usertype === 'doctor') list.splice(-1, 0, 'directMessage');
    return list;
};

export const formikIntialAndValidations = (data: any) => {
    return {
        initialValues: {
            firstName: data?.firstName ? decryptData(data.firstName) : '', // Decrypt the firstName
            lastName: data?.lastName ? decryptData(data.lastName) : '', // Decrypt the lastName
            email: data?.email ? decryptData(data.email) : '',
            gender: data?.gender || '',
            phoneNumber: data?.phoneNumber ? decryptData(data.phoneNumber) : '',
            dob: data?.dob ? decryptData(data.dob) : '',
            reason: data?.consultReason?.reason || '',
            laterality: data?.consultReason?.laterality || '',
            patientReturn: data?.consultReason?.patientReturn || false,
            timings: data?.consultReason?.timings || '',
            referedTo: data?.referedTo?.doctorId || '', // doctor userId from user table
            location: data?.Address?.id || '',
            //speciality: data?.speciality || '',
            insuranceId: data?.Insurance?.id || '',
            insurancePlanId: data?.InsurancePlan?.id || '',
            medicalDocuments: data?.medicalDocument || ([] as any[]),
            notes: data?.notes || '',
        },
        validationSchema: yup.object({
            firstName: yup
                .string()
                .max(20)
                .required('First Name is required')
                .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            lastName: yup
                .string()
                .max(20)
                .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            email: yup.string().email().required('Email is required'),
            gender: yup.string().required('Gender is required'),
            phoneNumber: yup
                .string()
                .min(8, 'Minimum 8 numbers')
                .required('Phone Number is required'),
            dob: yup
                .date()
                .max(dayjs(), 'DOB should be earlier than today')
                .required('DOB is required'),
            reason: yup.string().required('Reason is required'),
            laterality: yup.string().required('Laterality is required'),
            patientReturn: yup.boolean(),
            timings: yup.string().required('Timing is required'),
            referedTo: yup.string().required('Doctor Name is required'),
            location: yup.number().optional(),
            //speciality: yup.string().required('Required'),
            insuranceId: yup.number().nullable(),
            insurancePlanId: yup.number().optional(),
            medicalDocuments: yup.array().optional(),
            notes: yup
                .string()
                .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
    };
};

export const formikIntialAndValidationsOfStandardReferral = {
    initialValues: {
        doctorFirstName: '',
        doctorLastName: '',
        doctorEmail: '',
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        phoneNumber: '',
        dob: '',
        reason: '',
        laterality: '',
        patientReturn: false,
        timings: '',
        referedTo: '', // doctor userId from user table
        location: '',
        insuranceId: '',
        insurancePlanId: '',
        medicalDocuments: [] as any[],
        notes: '',
    },
    validationSchema: yup.object({
        doctorFirstName: yup
            .string()
            .max(20)
            .required('Doctor First Name is required')
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        doctorLastName: yup
            .string()
            .max(20)
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        doctorEmail: yup.string().email().required('Doctor Email is required'),
        firstName: yup
            .string()
            .max(20)
            .required('Patient First Name is required')
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        lastName: yup
            .string()
            .max(20)
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        email: yup.string().email().required('Patient Email is required'),
        gender: yup.string().required('Gender is required'),
        phoneNumber: yup
            .string()
            .min(8, 'Minimum 8 numbers')
            .required('Phone Number is required'),
        dob: yup
            .date()
            .max(dayjs(), 'DOB should be earlier than today')
            .required('DOB is required'),
        reason: yup.string().required('Reason is required'),
        laterality: yup.string().required('Laterality is required'),
        patientReturn: yup.boolean(),
        timings: yup.string().required('Timing is required'),
        referedTo: yup.string().required('Doctor Name is required'),
        location: yup.number().optional(),
        insuranceId: yup.number().nullable(),
        insurancePlanId: yup.number().optional(),
        medicalDocuments: yup.array().optional(),
        notes: yup
            .string()
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
};

export const profileIntialAndValidations = (data: any) => {
    return {
        initialValues: {
            firstName: data?.firstName || '',
            lastName: data?.lastName || '',
            email: data?.email || '',
            gender: data?.gender || '',
            phoneNumber: data?.phoneNumber || '',
        },
        validationSchema: yup.object({
            firstName: yup
                .string()
                .max(20)
                .required('First Name is required')
                .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            lastName: yup
                .string()
                .max(20)
                .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            email: yup.string().email().required('Email is required'),
            gender: yup.string(),
            phoneNumber: yup
                .string()
                .min(8, 'Minimum 8 numbers')
                .required('Phone Number is required'),
        }),
    };
};
