import { axios } from '@axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';
import { Address } from '@typing/global';
import { defaultParams } from '@utils/global.constants';

export const getDoctors = ({
    search = '',
    sort = 'firstName',
    order = 'ASC',
    page = 1,
    limit = 10,
}): Promise<any> => {
    return axios.get(
        `doctor?search=${search}&sort=${sort}&order=${order}&page=${page}&limit=${limit}`
    );
};

export const getDoctorById = ({ id }: { id: string }): Promise<any> => {
    return axios.get(`doctor/${id}`);
};

export const getDoctorAddresses = ({
    doctorId,
}: {
    doctorId: string;
}): Promise<any> => {
    return axios.get(`doctor/addresses/${doctorId}`);
};

export const getDoctorsOnlyHaveAddresses = (search: string): Promise<any> => {
    return axios.get(`doctor/with-addresses?search=${search}`);
};

type QueryFnType = typeof getDoctors;

type UseDoctorsOptions = {
    params?: {
        search?: string;
        sort?: string;
        order?: string;
        page?: number;
        limit?: number;
    };
    config?: QueryConfig<QueryFnType>;
};

type UseDoctorsByIdOptions = {
    id: string;
    config?: QueryConfig<QueryFnType>;
};

type UseDoctorsAddressesOptions = {
    doctorId: string;
    config?: QueryConfig<QueryFnType>;
};
type UseDoctorsOnlyWithAddressesOptions = {
    search: string;
    config?: QueryConfig<QueryFnType>;
};

export const useDoctors = ({
    params = defaultParams,
    config,
}: UseDoctorsOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...otherParams } = params;

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['doctors', otherParams],
        queryFn: () => getDoctors(params),
        select: (data: any) => data.data,
        ...config,
    });
};

export const useDoctorsById = ({ id, config }: UseDoctorsByIdOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['doctor', id],
        queryFn: () => (id ? getDoctorById({ id }) : false),
        select: (data: any) => data.data,
        ...config,
    });
};

export const useDoctorsAddresses = ({
    doctorId,
    config,
}: UseDoctorsAddressesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['doctor-addresses', doctorId],
        queryFn: () => (doctorId ? getDoctorAddresses({ doctorId }) : false),
        select: (data: { data: Address[] }) => data.data,
        ...config,
    });
};

type QueryDoctorsOnlyWithAddressesFnType = typeof getDoctors;

export const useDoctorsOnlyWithAddresses = ({
    search,
    config,
}: UseDoctorsOnlyWithAddressesOptions) => {
    return useQuery<ExtractFnReturnType<QueryDoctorsOnlyWithAddressesFnType>>({
        queryKey: ['doctor-with-addresses'],
        queryFn: () => getDoctorsOnlyHaveAddresses(search),
        select: (data: { data: any }) => data.data,
        ...config,
    });
};
