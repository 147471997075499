import React, { useEffect, useMemo } from 'react';
import './style.css';
import './standardReferral.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormik } from 'formik';
import TextInput from '@components/Common/TextInput';
import DateSelector from '@components/Common/DateSelector';
import SelectFiled from '@components/Common/SelectFiled';
import { formikIntialAndValidationsOfStandardReferral } from './constant';
import { Button as MuiButton, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@components/Common/Button';
import { addPatientStandardReferral } from '../../API/Patient';
import { failed, success } from '@components/Common/Toastify';
import { useDoctorInsurances, useInsurances } from '../../API/Insurance';
import {
    useDoctorsOnlyWithAddresses,
    useDoctorsAddresses,
} from '../../API/Doctor';
import dayjs from 'dayjs';
import TextArea from '@components/Common/TextArea';
import SearchSelectField from '@components/Common/SearchSelectField';
import { Insurances } from '@typing/global';
import { useModalDisclosure } from '@hooks/useDisclosure';
import ViewDocument from '@components/Common/ViewDocument';
import MedicalDocument from '@components/Common/MedicalDocument';
import ConfirmMsg from '@components/Common/ConfirmDialog';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '@images/logo.svg';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function AddStandardReferral() {
    const navigate = useNavigate();
    const { isOpen, close, open, data } = useModalDisclosure();
    const confirmModal = useModalDisclosure();

    const formik = useFormik({
        ...formikIntialAndValidationsOfStandardReferral,
        onSubmit: (values: any, actions) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                const val = values[key];
                if (key === 'medicalDocuments') {
                    // Append each file in the array with the same key
                    val.forEach((file: Blob) => {
                        formData.append('medicalDocuments', file);
                    });
                } else {
                    formData.append(key, val);
                }
            });
            addPatientStandardReferral(formData)
                .then((res: any) => {
                    navigate('/success');
                    success(res.message);
                })
                .catch((err) => {
                    failed(err.response.data.message);
                })
                .finally(() => actions.setSubmitting(false));
        },
    });

    const { data: insurancesList, isLoading: isInsurancesLoading } =
        useInsurances({ params: {} });

    const { data: DoctorInsurancesList } = useDoctorInsurances({
        doctorId: formik.values.referedTo,
    });

    const { data: doctorsAddressesList, isLoading: isDoctorsAddressesLoading } =
        useDoctorsAddresses({
            doctorId: formik.values.referedTo,
        }); // doctor's id from doctor table

    const { data: doctorsList, isLoading: isDoctorsLoading } =
        useDoctorsOnlyWithAddresses({ search: '' });

    const handleImageChange = (event: any) => {
        const docs = Array.from(event.target.files, (v) => v);
        formik.setFieldValue('medicalDocuments', docs);
        event.target.value = '';
    };

    const handleFormChange = (event: any) => {
        switch (event.target.name) {
            case 'referedTo':
                formik.setFieldValue('location', '');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (formik.values.insuranceId && formik.values.referedTo) {
            const hasInsurnace = DoctorInsurancesList?.some(
                (item: any) =>
                    item?.insuranceId === parseInt(formik.values.insuranceId)
            );
            if (!hasInsurnace) confirmModal.open();
        }
    }, [formik.values.insuranceId, formik.values.referedTo]);

    const doctorsSearchList = useMemo(() => {
        return (
            doctorsList?.map((item: any) => ({
                label: item.User.fullName,
                id: item.id,
            })) || []
        );
    }, [doctorsList]);

    const insurancesSearchList = useMemo(() => {
        return (
            insurancesList?.map((item: any) => ({
                label: item.companyName,
                id: item.id,
            })) || []
        );
    }, [insurancesList]);

    const insurancePlansList = useMemo(() => {
        return insurancesList
            ?.filter(
                (insurance: Insurances) =>
                    insurance.id === formik.values.insuranceId
            )?.[0]
            ?.InsurancePlans?.map((data: { plan: string; id: number }) => ({
                name: `${data.plan}`,
                value: data.id,
            }));
    }, [formik.values.insuranceId, insurancesSearchList]);

    return (
        <>
            <div className="main-header-div">
                <div className="main-header-flex">
                    <div className="logo-main-div">
                        <NavLink rel="stylesheet" to="/">
                            <img src={logo} alt="logo" className="logo-icon" />
                        </NavLink>
                        <MenuIcon className="menu-icon" />
                        <h4>EYE REFER</h4>
                    </div>
                </div>
            </div>
            <ConfirmMsg
                open={confirmModal.isOpen}
                title={'Warning'}
                message={"MD doesn't have the selected Insurance!"}
                showEntryName={null}
                entryName={null}
                showSecondarybutton={false}
                onConfirm={confirmModal.close}
                onDismiss={confirmModal.close}
                actionBtnTitle="OK"
            />
            <div
                className="main-add-div standard-referral"
                style={{ padding: '2rem' }}
            >
                <div className="add-heading">
                    <h4>Add Standard Referral</h4>
                </div>
                <form
                    className="add-form"
                    onSubmit={formik.handleSubmit}
                    onChange={handleFormChange}
                >
                    {/* basic information */}
                    <div className="heading-border-container">
                        <div className="heading-referral border-heading">
                            <h4>Doctor information</h4>
                        </div>
                        <div className="main-fields">
                            <TextInput
                                keyField={'doctorFirstName'}
                                label={'First Name'}
                                formik={formik}
                                className="inputfield-text"
                                placeholder={'First Name'}
                            />
                            <TextInput
                                keyField={'doctorLastName'}
                                label={'Last Name'}
                                formik={formik}
                                required={false}
                                className="inputfield-text"
                                placeholder={'Last Name'}
                            />
                            <TextInput
                                keyField={'doctorEmail'}
                                label={'Email'}
                                formik={formik}
                                className="inputfield-text"
                                placeholder={'Email'}
                            />
                        </div>
                    </div>

                    {/* basic information */}
                    <div
                        className="heading-border-container"
                        style={{ paddingBottom: '1rem' }}
                    >
                        <div className="heading-referral  border-heading">
                            <h4>Patient information</h4>
                        </div>
                        <div className="main-fields">
                            <DateSelector
                                keyField={'dob'}
                                label={'DOB'}
                                formik={formik}
                                minDate={dayjs().subtract(120, 'years')}
                                maxDate={dayjs()}
                            />
                            <TextInput
                                keyField={'email'}
                                label={'Email'}
                                formik={formik}
                                className="inputfield-text"
                                placeholder={'Email'}
                            />
                            <TextInput
                                keyField={'phoneNumber'}
                                label={'Phone Number'}
                                formik={formik}
                                type="phone"
                                placeholder={'Enter phone number'}
                            />
                        </div>
                        <div className="main-fields">
                            <TextInput
                                keyField={'firstName'}
                                label={'First Name'}
                                className="inputfield-text"
                                formik={formik}
                                placeholder={'Enter first name'}
                            />
                            <TextInput
                                keyField={'lastName'}
                                label={'Last Name'}
                                className="inputfield-text"
                                formik={formik}
                                required={false}
                                placeholder={'Enter last name'}
                            />
                            <SelectFiled
                                keyField={'gender'}
                                label={'Gender'}
                                formik={formik}
                                options={[
                                    { name: 'Male', value: 'male' },
                                    { name: 'Female', value: 'female' },
                                    { name: 'Other', value: 'other' },
                                ]}
                            />
                        </div>

                        {/* reason of consult */}
                        <div className="heading-referral">
                            <h4>Reason of consult</h4>
                        </div>
                        <div className="consult-div">
                            <SelectFiled
                                keyField={'reason'}
                                label={'Disease Name'}
                                formik={formik}
                                options={[
                                    { name: 'Cataract', value: 'cataract' },
                                    { name: 'Medical', value: 'medical' },
                                    {
                                        name: 'Keratoconus',
                                        value: 'keratoconus',
                                    },
                                    {
                                        name: 'Corneal, non-keratoconus',
                                        value: 'non-keratoconus',
                                    },
                                    { name: 'Other', value: 'other' },
                                ]}
                            />
                            <SelectFiled
                                keyField={'laterality'}
                                label={'Laterality'}
                                formik={formik}
                                options={[
                                    { name: 'Left', value: 'left' },
                                    { name: 'Right', value: 'right' },
                                    { name: 'Both', value: 'bilateral' },
                                ]}
                            />
                            <SelectFiled
                                keyField={'timings'}
                                label={'Timing'}
                                formik={formik}
                                options={[
                                    {
                                        name: 'Routine (Within 1 month)',
                                        value: 'routine',
                                    },
                                    {
                                        name: 'Urgent (Within 1 Week)',
                                        value: 'urgent',
                                    },
                                    {
                                        name: 'Emergent (within 24 hours or less)',
                                        value: 'emergent',
                                    },
                                ]}
                            />
                        </div>

                        {/* refrral od/md */}
                        <div className="heading-referral">
                            <h4>Refer to</h4>
                        </div>
                        <div className="main-fields">
                            <SearchSelectField
                                keyField={'referedTo'} // Key field for formik to use
                                label={'MD Name'} // Label for the search select field
                                formik={formik} // Formik object to handle form data
                                isLoading={isDoctorsLoading} // Loading state for fetching doctors list
                                options={doctorsSearchList}
                            />
                            <SelectFiled
                                keyField={'location'}
                                label={'Add Location'}
                                formik={formik}
                                required={false}
                                isLoading={isDoctorsAddressesLoading}
                                options={doctorsAddressesList?.map(
                                    (data: {
                                        addressTitle: string;
                                        id: number;
                                        city: string;
                                    }) => ({
                                        name: `${data.addressTitle}, ${data.city}`,
                                        value: data.id,
                                    })
                                )}
                            />
                        </div>

                        {/* insurance details */}
                        <div className="heading-referral">
                            <h4>Insurance Details</h4>
                        </div>
                        <div className="main-fields">
                            <SearchSelectField
                                keyField={'insuranceId'}
                                label={'Company Name'}
                                formik={formik}
                                required={false}
                                isLoading={isInsurancesLoading}
                                options={insurancesSearchList}
                            />
                            <SelectFiled
                                keyField={'insurancePlanId'}
                                label={'Insurance Plan'}
                                formik={formik}
                                required={false}
                                isLoading={isInsurancesLoading}
                                options={insurancePlansList}
                            />
                        </div>

                        <div className="heading-referral">
                            <h4>Medical Documents</h4>
                        </div>
                        <div
                            className="main-fields upload-div"
                            style={{ marginBottom: '5px' }}
                        >
                            <MuiButton
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload files
                                <VisuallyHiddenInput
                                    type="file"
                                    multiple
                                    accept=".pdf, .doc, .docx, .jpeg, .jpg, .png"
                                    onChange={handleImageChange}
                                />
                            </MuiButton>
                        </div>
                        <div className="docs">
                            <p>pdf, doc, jpeg, png</p>
                        </div>

                        {formik.values.medicalDocuments?.length > 0 ? (
                            <div className="upload-files-div">
                                {formik.values.medicalDocuments.map(
                                    (file: File, index: number) => (
                                        <MedicalDocument
                                            file={file}
                                            onClick={() => open(file)}
                                            key={index}
                                        />
                                    )
                                )}
                            </div>
                        ) : null}
                        <div className="note-div">
                            <TextArea
                                keyField={'notes'}
                                label={'Notes'}
                                formik={formik}
                                required={false}
                            />
                        </div>
                    </div>

                    <div className="bottom-btn">
                        <div className="bottom-btn-group">
                            <Button
                                variant="secondary"
                                disabled={!!formik?.isSubmitting}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <div className="patient-submit-btn">
                                <Button
                                    isLoading={formik.isSubmitting}
                                    type="submit"
                                    className="submit-btn"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {isOpen ? (
                <ViewDocument isOpen={isOpen} data={data} onClose={close} />
            ) : null}
        </>
    );
}

export default AddStandardReferral;
