export const statusColor = {
    pending: 'rgba(232, 250, 244, 1)',
    scheduled: 'rgba(255, 249, 228, 1)',
    cancelled: 'rgba(252, 236, 238, 1)',
    completed: 'rgba(67, 215, 158, 0.3)',
};

export const defaultParams = {
    search: '',
    sort: 'updatedAt',
    order: 'DESC',
    page: 1,
    limit: 10,
};
