import { axios } from '@axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';
import { defaultParams } from '@utils/global.constants';
import { ParamsType } from '@typing/global';

export type AddPatientReferral = {
    firstName: string;
    lastName: string;
    dob: string;
    email: string;
    gender: string;
    phoneNumber: string;
    location: string;
    reason: string;
    laterality: string;
    patientReturn: boolean;
    timings: string;
    referedTo: string;
    //speciality: string;
    insuranceId: string;
    insurancePlanId: string;
    documents: any[];
    notes: string;
};

export const getPatients = ({
    search = '',
    sort = 'firstName',
    order = 'ASC',
    page = 1,
    limit = 10,
    statusType = [],
    isDashboard = false,
}: ParamsType): Promise<any> => {
    return axios.get(
        `patient?search=${search}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&statusType=${JSON.stringify(
            statusType
        )}&isDashboard=${isDashboard}`
    );
};

export const getAllPatientsForAppointment = ({
    search = '',
    sort = 'fullname',
    order = 'ASC',
    statusType = [],
}: ParamsType): Promise<any> => {
    return axios.get(
        `patient/list/patients-name?search=${search}&sort=${sort}&order=${order}&statusType=${JSON.stringify(
            statusType
        )}`
    );
};

export const getPatientById = ({ id }: { id: string }): Promise<any> => {
    return axios.get(`patient/${id}`); // patient's User Id
};

export const getPatientMedicalDocuments = (
    referralId: string
): Promise<any> => {
    return axios.get(`patient/medical-documents/${referralId}`); // patient's User Id
};

export const deletePatient = ({ id }: { id: string }): Promise<any> => {
    return axios.delete(`patient/${id}`);
};

export const addPatientReferral = (payload: FormData): Promise<any> => {
    return axios.post(`patient/add-referral-patient`, payload);
};

export const addPatientStandardReferral = (payload: FormData): Promise<any> => {
    return axios.post(`patient/add-standard-referral`, payload);
};

export const addPatientReferralAsDraft = (
    payload: AddPatientReferral
): Promise<any> => {
    return axios.post(`patient/add-draft`, payload);
};

export const editPatientReferral = (
    id: string,
    payload: FormData
): Promise<any> => {
    return axios.put(`patient/edit-referral-patient/${id}`, payload);
};

export const patientReadyToReturn = (referralId: number): Promise<any> => {
    return axios.put(`patient/refer-completed/${referralId}`);
};

export const seenPatient = ({
    referralId,
}: {
    referralId: string;
}): Promise<any> => {
    return axios.put(`patient/seen/${referralId}`);
};

type QueryFnType = typeof getPatients;

type UsePatientsOptions = {
    params?: ParamsType;
    config?: QueryConfig<QueryFnType>;
};

type UsePatientsByIdOptions = {
    id: string;
    config?: QueryConfig<QueryFnType>;
};

type UseMedicalDocumentsOptions = {
    referralId: string;
    config?: QueryConfig<QueryFnType>;
};

export const usePatients = ({
    params = { ...defaultParams, statusType: [], isDashboard: false },
    config,
}: UsePatientsOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...otherParams } = params;

    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['patients', otherParams],
        queryFn: () => getPatients(params),
        select: (res: any) => res?.data || [],
        ...config,
    });
};

export const useGetAllPatientsForAppointment = ({
    params = {
        search: '',
        sort: 'fullName',
        order: 'DESC',
        statusType: ['pending', 'scheduled'],
    },
    config,
}: UsePatientsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['patients', params],
        queryFn: () => getAllPatientsForAppointment(params),
        select: (res: any) => res?.data || [],
        ...config,
    });
};

export const usePatientsById = ({ id, config }: UsePatientsByIdOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['patient', id],
        queryFn: () => (id ? getPatientById({ id }) : false), // patient's User Id
        select: (data: any) => data?.data || {},
        ...config,
    });
};

type QueryMedicalDocumentsFnType = typeof getPatientMedicalDocuments;

export const useMedicalDocuments = ({
    referralId,
    config,
}: UseMedicalDocumentsOptions) => {
    return useQuery<ExtractFnReturnType<QueryMedicalDocumentsFnType>>({
        queryKey: ['patient-medical-documents', referralId],
        queryFn: () =>
            referralId ? getPatientMedicalDocuments(referralId) : null,
        select: (data: any) => data?.data || [],
        ...config,
    });
};
